export default {
  en: {
    visitor_information: "Visitor Information",
    documents: "Documents",
    view_dashboard: "View Dashboard",
    add_visitor_information: "Add Visitor Information",
    edit_visitor_information: "Edit Visitor Information",     
    manage_visitor_questions: "Manage the additional questions asked to your visitors (Name & email are always required)",

  },
  nl: {
    sent: "Verzonden",
    tickets_from: "Tickets zijn beschikbaar vanaf",
    no_tickets_available_anymore: "Geen tickets meer beschikbaar",
    maximum: "Maximaal",
    visitor_information: "Bezoekersinformatie",
    documents: "Documenten",
    view_dashboard: "Dashboard Bekijken",
    add_visitor_information: "Bezoekersinformatie Toevoegen",
    edit_visitor_information: "Bezoekersinformatie Bewerken",
    manage_visitor_questions: "Beheer de extra vragen die aan jouw bezoekers worden gesteld (Naam & email worden altijd gevraagd)",
  },
};
