export default {
  en: {
    ticket_type: "Ticket Type",
    ticket_types: "Ticket Types",
    add_ticket_type: "Add Ticket Type",
    edit_ticket_type: "Edit Ticket Type",
    manage_ticket_types: "Manage the types of tickets for your visitors, e.g., Early Bird, Regular, VIP, etc.",
    new_group: "New Group",
    add_group: "Add Group",
    group_name: "Group",
    select_ticket_type: "Select Ticket Type",
    visitor_information: "Visitor Information",
    documents: "Documents",
    view_dashboard: "View Dashboard",
  },
  nl: {
    ticket_type: "Ticketsoort",
    ticket_types: "Ticketsoorten",
    add_ticket_type: "Ticketsoort Toevoegen",
    edit_ticket_type: "Ticketsoort Bewerken",
    manage_ticket_types: "Beheer de typen tickets voor jouw bezoekers, bijvoorbeeld: Early Bird, Regular, VIP, enzovoort.",
    new_group: "Nieuwe Groep",
    add_group: "Groep Toevoegen",
    group_name: "Groep",
    select_ticket_type: "Ticketsoort",
  },
};
