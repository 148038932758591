export default {
  en: {
    timeslot: "Timeslot",
    add_timeslot: "Add Timeslot",
    edit_timeslot: "Edit Timeslot",
    manage_timeslots: "Manage the timeslots for the activity",
    sent: "Sent",
    visitor_information: "Visitor Information",
    documents: "Documents",
    view_dashboard: "View Dashboard",
    
  },
  nl: {
    timeslot: "Tijdslot",
    add_timeslot: "Tijdslot Toevoegen",
    edit_timeslot: "Tijdslot Bewerken",      
    manage_timeslots: "Beheer de tijdsloten voor de activiteit",

  },
};
