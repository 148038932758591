import generic from "./i18n/generic";
import account from "./i18n/account.json";
import discounts from "./i18n/discounts.json";
import orders from "./i18n/orders";
import tickets from "./i18n/tickets";
import payments from "./i18n/payments";
import refunds from "./i18n/refunds";
import events from "./i18n/events";
import ticket_types from "./i18n/ticket_types";
import timeslot from "./i18n/timeslot";
import themes from "./i18n/themes";
import visitor_information from "./i18n/visitor_information";
import settings from "./i18n/settings";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "nl",
  messages: {
    en: {
      ...generic.en,
      ...account.en,
      ...orders.en,
      ...tickets.en,
      ...payments.en,
      ...refunds.en,
      ...events.en,
      ...settings.en,
      ...ticket_types.en,
      ...timeslot.en,
      ...themes.en,
      ...visitor_information.en,
      ...discounts.en,
    },
    nl: {
      ...generic.nl,
      ...account.nl,
      ...orders.nl,
      ...tickets.nl,
      ...payments.nl,
      ...refunds.nl,
      ...events.nl,
      ...settings.nl,
      ...ticket_types.nl,
      ...timeslot.nl,
      ...themes.nl,
      ...visitor_information.nl,
      ...discounts.nl,
    },
  },
}));
