export default {
  en: {
    order: "Order",
    create_order: "Create Order",
    fill_in_the_order_details: "Fill in the details to send tickets for free to guests.",
    visitor_information_optional: "Visitor information (optional)",
    place_order: "Place Free Order",
    order_at: "Order At",
    ordered_on: "Ordered On",
    order_details: "Order Details",
    order_limit: "Limit per order",
    order_cannot_be_deleted: "Order cannot be deleted",
    order_can_still_be_paid: "This order can still be paid",
    order_already_paid: "This order is already paid",
    confirm_delete_order: "Are you sure you want to delete this order?",
    edit_order_details: "Edit Order Details",
    send_confirmation_email_again: "Send confirmation email again",
    cancel_order: "Cancel Order",
    tickets_resent: "Tickets resent",
    tickets_resent_to: "Tickets resent to",
    cancellation: "Cancellation",
    tickets_for: "tickets for",
    fetching_transaction: "Fetching transaction...",
    contact_support: "Please contact info@tickable.nl",
    cancel_x_ticket: "Cancel ticket | Cancel {count} tickets",
    cancel_all_tickets: "Cancel All Tickets",
    resend_email: "Resend Email",
    waiting_for_payment: "Waiting for Payment",
    cancel_tickets: "Cancel tickets"
  },
  nl: {
    order: "Bestelling",
    create_order: "Bestelling maken",
    fill_in_the_order_details: "Vul de gegevens in om tickets gratis te versturen naar gasten.",
    visitor_information_optional: "Bezoekersgegevens (optioneel)",
    place_order: "Bestelling gratis plaatsen",
    order_at: "Besteld op",
    ordered_on: "Besteld op",
    order_details: "Bestellingsdetails",
    order_limit: "Limiet per bestelling",
    order_cannot_be_deleted: "Bestelling kan niet worden verwijderd",
    order_can_still_be_paid: "Deze bestelling kan nog worden betaald",
    order_already_paid: "Deze bestelling is al betaald",
    confirm_delete_order: "Weet je zeker dat je deze bestelling wilt verwijderen?",
    edit_order_details: "Wijzig de details van de bestelling",
    send_confirmation_email_again: "Bevestigingsmail opnieuw versturen",
    cancel_order: "Bestelling annuleren",
    tickets_resent: "Tickets opnieuw verstuurd",
    tickets_resent_to: "Tickets opnieuw verstuurd naar",
    cancellation: "Annulering",
    tickets_for: "tickets voor",
    fetching_transaction: "Transactie ophalen...",
    contact_support: "Neem contact op met info@tickable.nl",
    cancel_x_ticket: "Annuleer ticket | Annuleer {count} tickets",
    cancel_all_tickets: "Annuleer Alle Tickets",
    resend_email: "Opnieuw mailen",
    waiting_for_payment: "Wachten op betaling",
    cancel_tickets: "Annuleer Tickets"
  },
};
