import { default as confirmgBtLwh22y1Meta } from "/opt/buildhome/repo/pages/auth/confirm.vue?macro=true";
import { default as forgot_45passwordUaF9oDbyV0Meta } from "/opt/buildhome/repo/pages/auth/forgot-password.vue?macro=true";
import { default as _91invitation_id_938vIVK1tRroMeta } from "/opt/buildhome/repo/pages/auth/join/[invitation_id].vue?macro=true";
import { default as loginLsrADI0A5CMeta } from "/opt/buildhome/repo/pages/auth/login.vue?macro=true";
import { default as recovercFuX9yck5cMeta } from "/opt/buildhome/repo/pages/auth/recover.vue?macro=true";
import { default as registerPJjcn4pY50Meta } from "/opt/buildhome/repo/pages/auth/register.vue?macro=true";
import { default as strongPasswordteRgWqT5XEMeta } from "/opt/buildhome/repo/pages/auth/strongPassword.ts?macro=true";
import { default as authdjl0VO0QO1Meta } from "/opt/buildhome/repo/pages/auth.vue?macro=true";
import { default as accountfCLFdWAolfMeta } from "/opt/buildhome/repo/pages/index/index/account.vue?macro=true";
import { default as clearings0iJ6mpyhhfMeta } from "/opt/buildhome/repo/pages/index/index/clearings.vue?macro=true";
import { default as dashboardtmIiJkviHcMeta } from "/opt/buildhome/repo/pages/index/index/dashboard.vue?macro=true";
import { default as _91_91ticket_design_id_93_93pdZHX8bGmcMeta } from "/opt/buildhome/repo/pages/index/index/designer/[[ticket_design_id]].vue?macro=true";
import { default as defaultTemplatex8fsVwSTF7Meta } from "/opt/buildhome/repo/pages/index/index/designer/defaultTemplate.ts?macro=true";
import { default as emailsov3pJ8usfcMeta } from "/opt/buildhome/repo/pages/index/index/emails.vue?macro=true";
import { default as add_45questsRn4syL9kmfMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/add-quests.vue?macro=true";
import { default as agendaDsqGsfL7lwMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/agenda.vue?macro=true";
import { default as _91discount_id_93aJ6zO71KU7Meta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/discounts/[discount_id].vue?macro=true";
import { default as indexIxhMMkaiY5Meta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/discounts/index.vue?macro=true";
import { default as _91order_id_93LZn3gjmj0NMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/orders/[order_id].vue?macro=true";
import { default as indexHQD5JZ0hfkMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/orders/index.vue?macro=true";
import { default as overviewQoL7uAUF4sMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/overview.vue?macro=true";
import { default as seatsoAAPkCDjp4Meta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/seats.vue?macro=true";
import { default as indexySbxEdDCIhMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/index.vue?macro=true";
import { default as metadata8ONqjw0tdJMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/metadata.vue?macro=true";
import { default as morePv57IQZPQyMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/more.vue?macro=true";
import { default as themeqBooE3DAFrMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/theme.vue?macro=true";
import { default as ticket_45typesmp0R8IiStVMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/ticket-types.vue?macro=true";
import { default as timeslotsp3rXXgMcJzMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/timeslots.vue?macro=true";
import { default as settings2ROmHIuKSeMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/settings.vue?macro=true";
import { default as ticketswUxVXjYFqeMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/tickets.vue?macro=true";
import { default as _91event_id_930u8z9wU3ltMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id].vue?macro=true";
import { default as archivetlzNt8k8SRMeta } from "/opt/buildhome/repo/pages/index/index/events/index/archive.vue?macro=true";
import { default as indexjKgLpkN0t2Meta } from "/opt/buildhome/repo/pages/index/index/events/index.vue?macro=true";
import { default as new11re9urzVoMeta } from "/opt/buildhome/repo/pages/index/index/events/new.vue?macro=true";
import { default as indexNvnoVvr2yiMeta } from "/opt/buildhome/repo/pages/index/index/index/index.vue?macro=true";
import { default as refreshzccSkcUzDQMeta } from "/opt/buildhome/repo/pages/index/index/onboarding/refresh.vue?macro=true";
import { default as returnp6JENheEvGMeta } from "/opt/buildhome/repo/pages/index/index/onboarding/return.vue?macro=true";
import { default as ordersY0uUn9cPM7Meta } from "/opt/buildhome/repo/pages/index/index/orders.vue?macro=true";
import { default as managemCYfIstDuVMeta } from "/opt/buildhome/repo/pages/index/index/organization/manage.vue?macro=true";
import { default as _91_91ticket_design_id_93_93gLIXOSEbYDMeta } from "/opt/buildhome/repo/pages/index/index/report-designer/[[ticket_design_id]].vue?macro=true";
import { default as defaultTemplateGuugDOJLjlMeta } from "/opt/buildhome/repo/pages/index/index/report-designer/defaultTemplate.ts?macro=true";
import { default as allwVW3tBaZo8Meta } from "/opt/buildhome/repo/pages/index/index/scanners/all.vue?macro=true";
import { default as profilesGmCzJRHczVMeta } from "/opt/buildhome/repo/pages/index/index/scanners/profiles.vue?macro=true";
import { default as scannersaYFWFSeBOTMeta } from "/opt/buildhome/repo/pages/index/index/scanners.vue?macro=true";
import { default as seatmapShCFyMV45rMeta } from "/opt/buildhome/repo/pages/index/index/seatmap.vue?macro=true";
import { default as organizationPfy9hwcrc3Meta } from "/opt/buildhome/repo/pages/index/index/settings/organization.vue?macro=true";
import { default as paymentsFS4LgyAPJlMeta } from "/opt/buildhome/repo/pages/index/index/settings/payments.vue?macro=true";
import { default as themetsQlqUU3SoMeta } from "/opt/buildhome/repo/pages/index/index/settings/theme.vue?macro=true";
import { default as ticketsYy2dyQoN3MMeta } from "/opt/buildhome/repo/pages/index/index/settings/tickets.vue?macro=true";
import { default as usersHq9OC0LsFFMeta } from "/opt/buildhome/repo/pages/index/index/settings/users.vue?macro=true";
import { default as settingswH1eSDfpfgMeta } from "/opt/buildhome/repo/pages/index/index/settings.vue?macro=true";
import { default as _91id_93Hpjfe0BtvWMeta } from "/opt/buildhome/repo/pages/index/index/transactions/[id].vue?macro=true";
import { default as indexAHRpxqLipxMeta } from "/opt/buildhome/repo/pages/index/index/transactions/index.vue?macro=true";
import { default as refundsouK5sStpECMeta } from "/opt/buildhome/repo/pages/index/index/transactions/refunds.vue?macro=true";
import { default as _91order_id_93KiFKPr13O8Meta } from "/opt/buildhome/repo/pages/index/index/visitors/orders/[order_id].vue?macro=true";
import { default as indexS7li2iDiR6Meta } from "/opt/buildhome/repo/pages/index/index/visitors/orders/index.vue?macro=true";
import { default as visitorsiXPg1mgCdsMeta } from "/opt/buildhome/repo/pages/index/index/visitors.vue?macro=true";
import { default as indexCMVanMCzFOMeta } from "/opt/buildhome/repo/pages/index/index.vue?macro=true";
import { default as new_45organizationbrOILcBBHEMeta } from "/opt/buildhome/repo/pages/index/new-organization.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as unsupported_45browser6bhoGhxRyZMeta } from "/opt/buildhome/repo/pages/unsupported-browser.vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    component: () => import("/opt/buildhome/repo/pages/auth.vue"),
    children: [
  {
    name: "auth-confirm",
    path: "confirm",
    component: () => import("/opt/buildhome/repo/pages/auth/confirm.vue")
  },
  {
    name: "auth-forgot-password",
    path: "forgot-password",
    component: () => import("/opt/buildhome/repo/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-join-invitation_id",
    path: "join/:invitation_id()",
    component: () => import("/opt/buildhome/repo/pages/auth/join/[invitation_id].vue")
  },
  {
    name: "auth-login",
    path: "login",
    component: () => import("/opt/buildhome/repo/pages/auth/login.vue")
  },
  {
    name: "auth-recover",
    path: "recover",
    component: () => import("/opt/buildhome/repo/pages/auth/recover.vue")
  },
  {
    name: "auth-register",
    path: "register",
    component: () => import("/opt/buildhome/repo/pages/auth/register.vue")
  },
  {
    name: "auth-strongPassword",
    path: "strongPassword",
    component: () => import("/opt/buildhome/repo/pages/auth/strongPassword.ts")
  }
]
  },
  {
    name: indexniDiYCWjuTMeta?.name,
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue"),
    children: [
  {
    name: indexCMVanMCzFOMeta?.name,
    path: "",
    component: () => import("/opt/buildhome/repo/pages/index/index.vue"),
    children: [
  {
    name: "index-index-account",
    path: "account",
    component: () => import("/opt/buildhome/repo/pages/index/index/account.vue")
  },
  {
    name: "index-index-clearings",
    path: "clearings",
    component: () => import("/opt/buildhome/repo/pages/index/index/clearings.vue")
  },
  {
    name: "index-index-dashboard",
    path: "dashboard",
    component: () => import("/opt/buildhome/repo/pages/index/index/dashboard.vue")
  },
  {
    name: "index-index-designer-ticket_design_id",
    path: "designer/:ticket_design_id?",
    component: () => import("/opt/buildhome/repo/pages/index/index/designer/[[ticket_design_id]].vue")
  },
  {
    name: "index-index-designer-defaultTemplate",
    path: "designer/defaultTemplate",
    component: () => import("/opt/buildhome/repo/pages/index/index/designer/defaultTemplate.ts")
  },
  {
    name: "index-index-emails",
    path: "emails",
    component: () => import("/opt/buildhome/repo/pages/index/index/emails.vue")
  },
  {
    name: "index-index-events-event_id",
    path: "events/:event_id()",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id].vue"),
    children: [
  {
    name: "index-index-events-event_id-add-quests",
    path: "add-quests",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/add-quests.vue")
  },
  {
    name: "index-index-events-event_id-agenda",
    path: "agenda",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/agenda.vue")
  },
  {
    name: "index-index-events-event_id-discounts-discount_id",
    path: "discounts/:discount_id()",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/discounts/[discount_id].vue")
  },
  {
    name: "index-index-events-event_id-discounts",
    path: "discounts",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/discounts/index.vue")
  },
  {
    name: "index-index-events-event_id-orders-order_id",
    path: "orders/:order_id()",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/orders/[order_id].vue")
  },
  {
    name: "index-index-events-event_id-orders",
    path: "orders",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/orders/index.vue")
  },
  {
    name: "index-index-events-event_id-overview",
    path: "overview",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/overview.vue")
  },
  {
    name: "index-index-events-event_id-seats",
    path: "seats",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/seats.vue")
  },
  {
    name: settings2ROmHIuKSeMeta?.name,
    path: "settings",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/settings.vue"),
    children: [
  {
    name: "index-index-events-event_id-settings",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/index.vue")
  },
  {
    name: "index-index-events-event_id-settings-metadata",
    path: "metadata",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/metadata.vue")
  },
  {
    name: "index-index-events-event_id-settings-more",
    path: "more",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/more.vue")
  },
  {
    name: "index-index-events-event_id-settings-theme",
    path: "theme",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/theme.vue")
  },
  {
    name: "index-index-events-event_id-settings-ticket-types",
    path: "ticket-types",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/ticket-types.vue")
  },
  {
    name: "index-index-events-event_id-settings-timeslots",
    path: "timeslots",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/timeslots.vue")
  }
]
  },
  {
    name: "index-index-events-event_id-tickets",
    path: "tickets",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/tickets.vue")
  }
]
  },
  {
    name: "index-index-events",
    path: "events",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/index.vue"),
    children: [
  {
    name: "index-index-events-index-archive",
    path: "archive",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/index/archive.vue")
  }
]
  },
  {
    name: "index-index-events-new",
    path: "events/new",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/new.vue")
  },
  {
    name: "index-index-index",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/index/index/index/index.vue")
  },
  {
    name: "index-index-onboarding-refresh",
    path: "onboarding/refresh",
    component: () => import("/opt/buildhome/repo/pages/index/index/onboarding/refresh.vue")
  },
  {
    name: "index-index-onboarding-return",
    path: "onboarding/return",
    component: () => import("/opt/buildhome/repo/pages/index/index/onboarding/return.vue")
  },
  {
    name: "index-index-orders",
    path: "orders",
    component: () => import("/opt/buildhome/repo/pages/index/index/orders.vue")
  },
  {
    name: "index-index-organization-manage",
    path: "organization/manage",
    component: () => import("/opt/buildhome/repo/pages/index/index/organization/manage.vue")
  },
  {
    name: "index-index-report-designer-ticket_design_id",
    path: "report-designer/:ticket_design_id?",
    component: () => import("/opt/buildhome/repo/pages/index/index/report-designer/[[ticket_design_id]].vue")
  },
  {
    name: "index-index-report-designer-defaultTemplate",
    path: "report-designer/defaultTemplate",
    component: () => import("/opt/buildhome/repo/pages/index/index/report-designer/defaultTemplate.ts")
  },
  {
    name: "index-index-scanners",
    path: "scanners",
    component: () => import("/opt/buildhome/repo/pages/index/index/scanners.vue"),
    children: [
  {
    name: "index-index-scanners-all",
    path: "all",
    component: () => import("/opt/buildhome/repo/pages/index/index/scanners/all.vue")
  },
  {
    name: "index-index-scanners-profiles",
    path: "profiles",
    component: () => import("/opt/buildhome/repo/pages/index/index/scanners/profiles.vue")
  }
]
  },
  {
    name: "index-index-seatmap",
    path: "seatmap",
    component: () => import("/opt/buildhome/repo/pages/index/index/seatmap.vue")
  },
  {
    name: "index-index-settings",
    path: "settings",
    component: () => import("/opt/buildhome/repo/pages/index/index/settings.vue"),
    children: [
  {
    name: "index-index-settings-organization",
    path: "organization",
    component: () => import("/opt/buildhome/repo/pages/index/index/settings/organization.vue")
  },
  {
    name: "index-index-settings-payments",
    path: "payments",
    component: () => import("/opt/buildhome/repo/pages/index/index/settings/payments.vue")
  },
  {
    name: "index-index-settings-theme",
    path: "theme",
    component: () => import("/opt/buildhome/repo/pages/index/index/settings/theme.vue")
  },
  {
    name: "index-index-settings-tickets",
    path: "tickets",
    component: () => import("/opt/buildhome/repo/pages/index/index/settings/tickets.vue")
  },
  {
    name: "index-index-settings-users",
    path: "users",
    component: () => import("/opt/buildhome/repo/pages/index/index/settings/users.vue")
  }
]
  },
  {
    name: "index-index-transactions-id",
    path: "transactions/:id()",
    component: () => import("/opt/buildhome/repo/pages/index/index/transactions/[id].vue")
  },
  {
    name: "index-index-transactions",
    path: "transactions",
    component: () => import("/opt/buildhome/repo/pages/index/index/transactions/index.vue")
  },
  {
    name: "index-index-transactions-refunds",
    path: "transactions/refunds",
    component: () => import("/opt/buildhome/repo/pages/index/index/transactions/refunds.vue")
  },
  {
    name: "index-index-visitors",
    path: "visitors",
    component: () => import("/opt/buildhome/repo/pages/index/index/visitors.vue"),
    children: [
  {
    name: "index-index-visitors-orders-order_id",
    path: "orders/:order_id()",
    component: () => import("/opt/buildhome/repo/pages/index/index/visitors/orders/[order_id].vue")
  },
  {
    name: "index-index-visitors-orders",
    path: "orders",
    component: () => import("/opt/buildhome/repo/pages/index/index/visitors/orders/index.vue")
  }
]
  }
]
  },
  {
    name: "index-new-organization",
    path: "new-organization",
    component: () => import("/opt/buildhome/repo/pages/index/new-organization.vue")
  }
]
  },
  {
    name: "unsupported-browser",
    path: "/unsupported-browser",
    component: () => import("/opt/buildhome/repo/pages/unsupported-browser.vue")
  }
]