export default {
  en: {
    payment: "Payment",
    payment_method: "Payment Method",
    after_each_event: "After Each Event",
    weekly_payout: "Weekly Payout",
    mollie: "Mollie",
    bank_account: "Bank Account",
    iban_number: "IBAN Number",
    account_holder_name: "Account Holder Name",
    invalid_iban_format: "Invalid IBAN Format",
    enter_account_holder_name: "Enter Account Holder Name",
    weekly_payout_info: "We work with Stripe to ensure secure payments.",
    provider_received_info: "Our payment provider has received all information",
    provider_received_bank_info: "Our payment provider has your bank account details for payout",
    manage_payout: "Manage Payout",
    manage_weekly_payout: "Manage Your Weekly Payout",
    verify_payout: "Verify Payout",
    payouts: "Payouts",
    download_payout_report: "Download your payout report below",
    mollie_account: "Mollie Account",
    mollie_profile: "Mollie Profile",
    payment_dashboard: "Payment Dashboard",
    need_more_info: "We need more information to activate payments",
    activate_payments: "Activate Payments",
    working_with_stripe: "Working with Stripe",
    stripe_recognized_by_dnb: "Stripe is officially recognized by De Nederlandsche Bank and has the right licenses to process iDeal payments.",
    have_mollie_account: "Do you already have a Mollie account?",
    can_also_paid_via_mollie: "Yes can also be paid via Mollie:",
    click_here: "Click here",
    verify_account: "Verify Account",
    revenue: "Revenue",
    sent: "Sent",
    visitor_information: "Visitor Information",
    documents: "Documents",
    view_dashboard: "View Dashboard",
  },
  nl: {
    payment: "Betaling",
    payment_method: "Methode",
    after_each_event: "Na elk evenement",
    weekly_payout: "Wekelijkse uitbetaling",
    mollie: "Mollie",
    bank_account: "Bankrekening",
    iban_number: "IBAN nummer",
    account_holder_name: "Rekening op naam van",
    invalid_iban_format: "IBAN nummber heeft geen geldig formaat",
    enter_account_holder_name: "Vul de naam in van de rekening houder",
    weekly_payout_info: "We werken samen met stripe om betalingen veilig te laten verlopen.",
    payment_dashboard: "Betaling dashboard",
    provider_received_info: "Onze betaalprovider heeft alle informatie ontvangen",
    provider_received_bank_info: "Onze betaalprovider heeft jouw bankrekening gegevens voor de uitbetaling",
    manage_payout: "Uitbetaling beheren",
    manage_weekly_payout: "Jouw weekelijkse uitbetaling beheren",
    verify_payout: "Uitbetaling Verifiëren",
    payouts: "Uitbetalingen",
    download_payout_report: "Download hieronder jouw uitbetaling rapport",
    mollie_account: "Mollie-account",
    mollie_profile: "Mollie profiel",
    need_more_info: "We hebben meer informatie nodig om betalingen te activeren",
    activate_payments: "Betalingen activeren",
    working_with_stripe: "We werken samen met Stripe",
    stripe_recognized_by_dnb: "Stripe is officieel erkend door De Nederlandsche Bank en beschikt over de juiste vergunningen om iDeal-betalingen te verwerken.",
    have_mollie_account: "Heb je al een mollie account?",
    can_also_paid_via_mollie: "Ja kan ook via mollie uitbetaald worden:",
    click_here: "Klik hier",
    verify_account: "Account Verifiëren",
    revenue: "Omzet",
  },
};
