export default {
  en: {
    create_refund: "Create a refund?",
    refund_description: "Description on refund",
    refund_amount: "Amount for refund",
    iban_for_refund: "IBAN for refund",
    iban_holder_name: "IBAN holder name",
    no_automatic_cancellation_emails: "No automatic emails are sent for cancellations.",
    cannot_refund_automatically: "We cannot automatically refund this payment. Please contact info@tickable.nl or do this yourself via mollie.",
    refund_created_mollie: "Refund created in Mollie",
    can_cancel_in_mollie_dashboard: "You can cancel this in the Mollie dashboard within the next two hours.",
    refund_creation_failed: "Refund could not be created",
    try_mollie_or_contact_support: "Try this yourself in Mollie or contact info@tickable.nl",
    refund_created: "Refund created",
    sent: "Sent",
    visitor_information: "Visitor Information",
    documents: "Documents",
    view_dashboard: "View Dashboard",
  },
  nl: {
    create_refund: "Een terugbetaling aanmaken?",
    refund_description: "Beschrijving op terugbetaling",
    refund_amount: "Bedrag voor terugbetaling",
    iban_for_refund: "IBAN voor terugbetaling",
    iban_holder_name: "IBAN naamhouder",
    no_automatic_cancellation_emails: "Er worden nog geen automatische mails verstuurd voor annuleringen.",
    cannot_refund_automatically: "We kunnen deze betaling niet automatisch terug betalen. Neem contact op met info@tickable.nl of doe dit zelf via mollie.",
    refund_created_mollie: "Terugbetaling aangemaakt in mollie",
    can_cancel_in_mollie_dashboard: "Deze kan je komende twee uur nog annuleren in het mollie dashboard.",
    refund_creation_failed: "Terugbetaling kon niet worden aangemaakt",
    try_mollie_or_contact_support: "Probeer dit zelf bij mollie of neem contact op met info@tickable.nl",
    refund_created: "Terugbetaling aangemaakt",
  },
};
