<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})

</script>
<template>
  <div class="min-h-screen lg:static flex w-full">
    <div class="flex-1 flex justify-center">
    <Error :error="props.error" logo/>
    </div>
  </div>
</template>